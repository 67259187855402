import React from 'react'
import { graphql, Link } from 'gatsby'
import Helmet from 'react-helmet'
import get from 'lodash/get'
import Img from 'gatsby-image'
import Layout from '../components/layout'

import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
} from 'pure-react-carousel'
import 'pure-react-carousel/dist/react-carousel.es.css'

const BlogPostTemplate = props => {
  const post = get(props, 'data.contentfulBrunch')
  const siteTitle = get(props, 'data.site.siteMetadata.title')
  return (
    <Layout location={props.location}>
      <Helmet title={`${post.title} | ${siteTitle}`} />
      <section className="brunch-post">
        <div className="brunch-post__title grid-container grid-container--full site-wide">
          <div className="grid-column">
            <h1>{post.title}</h1>
          </div>
        </div>

        <div className="brunch-post__meta">
          <div className="grid-container grid-container--full site-wide">
            <div className="grid-column">
              <h2 className="h4-styling">{post.location}</h2>

              <div className="brunch-post__stats">
                <div className="stat">
                  <div className="stat__value">
                    <h5>{post.duration}</h5>
                  </div>
                  <div className="stat__type meta-tag">mins</div>
                </div>

                <div className="stat">
                  <div className="stat__value">
                    <h5>{post.price}</h5>
                  </div>
                  <div className="stat__type meta-tag">{post.currency}</div>
                </div>

                <div className="stat">
                  <div className="stat__value">
                    <h5>{post.stars}</h5>
                  </div>
                  <div className="stat__type meta-tag">stars</div>
                </div>
              </div>
            </div>
          </div>

          <div className="grid-container grid-container--full site-wide">
            <div className="grid-column">
              <div className="brunch-post__intro">
                <Img
                  alt={post.title}
                  fluid={post.heroImage.fluid}
                  className="brunch-post__intro-image"
                />

                <div
                  className="brunch-post__intro-text"
                  dangerouslySetInnerHTML={{
                    __html: post.heroCaption.childMarkdownRemark.html,
                  }}
                />
              </div>
            </div>
          </div>
        </div>

        <div className="brunch-post__drink grid-container grid-container--full site-wide">
          <div className="grid-column">
            <div className="brunch-post__image-container">
              <h2>Drinks</h2>

              <div className="carousel-large">
                <CarouselProvider
                  naturalSlideWidth={861}
                  naturalSlideHeight={500}
                  totalSlides={post.drinksImages.length}
                >
                  <Slider>
                    {post.drinksImages.map((img, index) => (
                      <Slide index key={img.id}>
                        <Img
                          alt={post.title}
                          fluid={img.fluid}
                          className="brunch-post__image"
                          loading="lazy"
                        />
                      </Slide>
                    ))}
                  </Slider>
                  {post.drinksImages.length > 1 && (
                    <div className="carousel__buttons carousel__buttons--bottom">
                      <ButtonBack>&larr;</ButtonBack>
                      <ButtonNext>&rarr;</ButtonNext>
                    </div>
                  )}
                </CarouselProvider>
              </div>
              <div
                className="brunch-post__hightlighted-text bg-grey-light"
                dangerouslySetInnerHTML={{
                  __html: post.drinksIntro.childMarkdownRemark.html,
                }}
              />
            </div>

            <div
              className="brunch-post__text"
              dangerouslySetInnerHTML={{
                __html: post.drinksBody.childMarkdownRemark.html,
              }}
            />
          </div>
        </div>

        <div className="brunch-post__food">
          <div className="grid-container grid-container--full site-wide">
            <div className="grid-column">
              <div className="brunch-post__image-container">
                <div className="carousel-small">
                  <CarouselProvider
                    naturalSlideWidth={750}
                    naturalSlideHeight={500}
                    totalSlides={post.foodImages.length}
                  >
                    <Slider>
                      {post.foodImages.map((img, index) => (
                        <Slide index key={img.id}>
                          <Img
                            alt={post.title}
                            fluid={img.fluid}
                            className="brunch-post__image"
                            loading="lazy"
                          />
                        </Slide>
                      ))}
                    </Slider>
                    {post.foodImages.length > 1 && (
                      <div className="carousel__buttons carousel__buttons--top">
                        <ButtonBack>&larr;</ButtonBack>
                        <ButtonNext>&rarr;</ButtonNext>
                      </div>
                    )}
                  </CarouselProvider>
                </div>

                <div className="brunch-post__hightlighted-text bg-white">
                  <h2>Food</h2>
                  <div
                    dangerouslySetInnerHTML={{
                      __html: post.foodIntro.childMarkdownRemark.html,
                    }}
                  />
                </div>
              </div>

              <div
                className="brunch-post__text"
                dangerouslySetInnerHTML={{
                  __html: post.foodBody.childMarkdownRemark.html,
                }}
              />
            </div>
          </div>
        </div>

        <div className="brunch-post__venue grid-container grid-container--full site-wide">
          <div className="grid-column">
            <div className="brunch-post__image-container">
              <div className="carousel-small">
                <CarouselProvider
                  naturalSlideWidth={750}
                  naturalSlideHeight={500}
                  totalSlides={post.venueImages.length}
                >
                  <Slider>
                    {post.venueImages.map((img, index) => (
                      <Slide index key={img.id}>
                        <Img
                          alt={post.title}
                          fluid={img.fluid}
                          className="brunch-post__image"
                          loading="lazy"
                        />
                      </Slide>
                    ))}
                  </Slider>
                  {post.venueImages.length > 1 && (
                    <div className="carousel__buttons carousel__buttons--bottom">
                      <ButtonBack>&larr;</ButtonBack>
                      <ButtonNext>&rarr;</ButtonNext>
                    </div>
                  )}
                </CarouselProvider>
              </div>

              <div className="brunch-post__hightlighted-text bg-white">
                <h2>Venue</h2>
                <div
                  dangerouslySetInnerHTML={{
                    __html: post.venueIntro.childMarkdownRemark.html,
                  }}
                />
              </div>
            </div>

            <div
              className="brunch-post__text"
              dangerouslySetInnerHTML={{
                __html: post.venueBody.childMarkdownRemark.html,
              }}
            />
          </div>
        </div>

        {props.pageContext.next ? (
          <Link
            to={`/${props.pageContext.next.slug}`}
            className="brunch-post__next"
          >
            <img
              src={props.pageContext.next.heroImage.file.url}
              alt={post.title}
              className="brunch-post__next-image"
              loading="lazy"
            />

            <div className="brunch-post__next-text">
              <p className="h2-styling">{post.nextBlogText}</p>
              <p className="h4-styling">View next brunch</p>
            </div>
          </Link>
        ) : (
          <Link to={`/`} className="brunch-post__next">
            <img
              src={'/times-up.jpg'}
              alt="Time's Up"
              className="brunch-post__next-image"
              loading="lazy"
            />

            <div className="brunch-post__next-text">
              <p className="h2-styling">Time's up!</p>
              <p className="h4-styling">View all brunches</p>
            </div>
          </Link>
        )}
      </section>
    </Layout>
  )
}
export default BlogPostTemplate

export const pageQuery = graphql`
  query BrunchBySlug($slug: String!) {
    site {
      siteMetadata {
        title
      }
    }
    contentfulBrunch(slug: { eq: $slug }) {
      title
      slug
      location
      currency
      price
      stars
      duration
      publishDate(formatString: "MMMM Do, YYYY")
      heroImage {
        fluid(resizingBehavior: SCALE, maxWidth: 1000, quality: 100) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      heroCaption {
        childMarkdownRemark {
          html
        }
      }
      drinksImages {
        fluid(resizingBehavior: SCALE, maxWidth: 800, quality: 100) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      drinksIntro {
        childMarkdownRemark {
          html
        }
      }
      drinksBody {
        childMarkdownRemark {
          html
        }
      }
      foodImages {
        fluid(resizingBehavior: SCALE, maxWidth: 800, quality: 100) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      foodIntro {
        childMarkdownRemark {
          html
        }
      }
      foodBody {
        childMarkdownRemark {
          html
        }
      }
      venueImages {
        fluid(resizingBehavior: SCALE, maxWidth: 800, quality: 100) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      venueIntro {
        childMarkdownRemark {
          html
        }
      }
      venueBody {
        childMarkdownRemark {
          html
        }
      }
      nextBlogText
    }
  }
`
